<template>
  <login-layout>
    <template #title>
      <h1>{{ $t('welcome!')?.capitalize() }}</h1>
      <h2>{{ $t("we're glad to see you")?.proper() }}</h2>
    </template>

    <form role="form">
      <input type="hidden" name="_token" :value="csrfToken" />

      <v-card
        elevation="10"
        class="my-12"
        :class="{ 'mx-4': $vuetify.display.smAndDown, 'mx-12': $vuetify.display.mdAndUp }"
      >
        <v-card-text>
          <v-alert v-if="errors.message" type="error" density="compact">{{ errors.message }}</v-alert>

          <v-text-field
            id="email"
            v-model="email"
            type="email"
            name="email"
            :label="$t('email address')?.capitalize()"
            :error-messages="errors.email"
            required
            density="compact"
            prepend-icon="mdi-email"
          />

          <v-text-field
            id="password"
            v-model="password"
            type="password"
            name="password"
            :label="$t('password')?.capitalize()"
            :error-messages="errors.password"
            prepend-icon="mdi-account-lock"
            density="compact"
            required
          />

          <v-checkbox
            id="remember-me"
            v-model="remember"
            name="remember"
            :label="$t('remember me')?.capitalize()"
            density="compact"
          />
        </v-card-text>
      </v-card>

      <v-main class="login-section">
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn id="login-btn" :loading="loading" rounded elevation="10" variant="flat" @click="login">
              {{ $t('login')?.capitalize() }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn class="forgot" variant="text" to="/password/forgot">
              {{ $t('forgot your password?')?.proper() }}
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </form>
  </login-layout>
</template>
<script>
import store from '../store';
import LoginLayout from '../components/LoginLayout.vue';
import axios from '../plugins/axios';

export default {
  name: 'LoginView',
  components: { LoginLayout },
  data: () => ({
    loading: false,
    errors: {
      message: null,
      email: null,
      password: null,
    },
    email: null,
    password: null,
    remember: false,
  }),
  computed: {
    csrfToken() {
      return window.Laravel.csrfToken;
    },
  },
  mounted() {
    store.commit('breadcrumbs/setBreadcrumbs', []);

    window.addEventListener('keyup', this.addKeyupListener);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.addKeyupListener);
  },
  methods: {
    addKeyupListener(event) {
      if (event.key === 'Enter') {
        this.login();
      }
    },
    async login() {
      this.loading = true;
      try {
        const res = await axios.post('/login', {
          email: this.email,
          password: this.password,
          remember: this.remember,
        });

        if (res.data.errors) {
          this.errors = res.data.errors;
          return;
        }

        // get the user's data
        const user = res.data.payload.user;
        this.$store.commit('user/setUser', user);
        this.$store.commit('organization/setOrganization', user.default_organization);
        this.$store.dispatch('initApplicationData');

        if (typeof gtag === 'function') {
          gtag('set', {
            user_id: `${user.id}-${this.email}`,
          });
        }

        // success
        if (res.data.payload.redirect) {
          return this.$router.push(res.data.payload.redirect);
        }

        return await this.$router.push({ name: 'dashboard' });
      } catch (error) {
        console.error({ error });
        if (error.response?.data.payload) {
          this.errors.message = error.response.data.payload.message;
        }

        if (error.response?.data.errors) {
          this.errors = error.response.data.errors;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.welcome {
  text-align: center;
  margin-bottom: 50px;
}

h1,
h2 {
  font-weight: 400;
}

.login-section {
  text-align: center;
  margin-top: 50px;
}

#login-btn {
  width: 300px;
  height: 55px;
  color: white;
  background: linear-gradient(267.78deg, #ff9001 36.19%, #ff4200 116.03%);
  font-size: 20px;
}

.forgot {
  margin-top: 50px;
  font-size: 20px;
}

.v-card {
  padding: 44px 20px 30px 20px;
}

.v-text-field,
.v-checkbox {
  margin-top: 10px;
}

.v-application .black {
  background-color: #000000 !important;
  opacity: 0.84;
}

@media screen and (max-width: 600px) {
  #inner-card {
    margin: 5% 10% !important;
  }

  .v-card__text,
  .v-card__title {
    padding: 0;
  }

  .v-card__actions {
    padding: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}
</style>
