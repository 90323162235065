import { utils } from '../../utils/utils';
import axios from '../../plugins/axios.js';

const defaultFilters = {
  building: 0,
  client: 0,
  scheduler: 0,
  manager: 0,
  dateType: 'scheduled date',
  dateFrom: null,
  dateTo: null,
  recurring: null,
  invoice: null,
  invoiceNumber: null,
  type: null,
  monthView: 'current',
  status: 'scheduled',
  headers: [],
  header: 'due_date',
  headerSortDirection: 'ASC',
};

const state = () => ({
  calendarView: false,
  fetching: false,
  specialProjects: [],
  filters: defaultFilters,
  statuses: {
    // these are not used now.
    // proposed: 'proposed',
    // closed: 'closed',
    // on_hold: 'on_hold',
    // cancelled: 'cancelled',
    draft: 'draft',
    active: 'active',
    inactive: 'inactive',
  },
  statusColor: {
    draft: '#5491F9',
    active: '#31CF97',
    inactive: '#96999C',
    // scheduled: '#31CF97',
    // draft: '#96999C',
    // proposed: '#5491F9',
    // invoiced: '#5491F9',
    // on_hold: '#FF9001',
    // closed: '#96999C',
  },
  projectTypes: [
    'acid washing',
    'appliance cleaning',
    'carpet cleaning',
    'detail cleaning',
    'extra labor request',
    'glass cleaning',
    'field bonus',
    'hard surface floor work',
    'product bill-back',
    'subcontract',
    'upholstery cleaning',
    'cleancode',
    'other',
  ],
  recurrenceIntervals: ['day', 'week', 'month', 'year'],
  statusLabels: {
    pending: 'pending',
    active: 'active',
    inactive: 'inactive',
    draft: 'draft',
    proposed: 'proposed',
    scheduled: 'scheduled',
    open: 'scheduled',
    closed: 'closed',
    invoiced: 'invoiced',
    on_hold: 'on hold',
    cancelled: 'cancelled',
  },
});

const getters = {
  specialProjects: (state) => {
    return state.specialProjects;
  },
  statuses: (state) => {
    return state.statuses;
  },
  statusColor: (state) => {
    return state.statusColor;
  },
  getStatusColor: (state) => (status) => {
    return state.statusColor[status];
  },
  filters: (state) => {
    return state.filters;
  },
  projectTypes: (state) => {
    return state.projectTypes;
  },
  types: (state) => {
    return state.projectTypes.map((t) => {
      return {
        text: utils.capitalize(t),
        value: t,
      };
    });
  },
  recurrenceIntervals: (state) => {
    return state.recurrenceIntervals;
  },
  recurrenceIntervalsOptions: () => [
    { value: 'none', text: utils.proper('does not repeat') },
    { value: 'day', text: utils.proper('repeat daily') },
    { value: 'week', text: utils.proper('repeat weekly') },
    { value: 'month', text: utils.proper('repeat monthly') },
    { value: 'year', text: utils.proper('repeat yearly') },
    { value: 'custom', text: utils.proper('custom') },
  ],
  calendarView: (state) => {
    return state.calendarView;
  },
  dateTypes: (state) => {
    return state.dateTypes;
  },
};

const actions = {
  get(context) {
    // only query if we do not already have them.
    if (context.state.fetching !== true && context.state.specialProjects.length === 0) {
      context.commit('updateFetching', true);
      axios.get('/api/v1/core/special-projects').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateSpecialProjects', response.data.payload.specialProjects);
      });
    }
  },
  getWithParams(context, params) {
    let queryString = '';

    if (params) {
      queryString = utils.formatQueryString(params);
    }

    return axios.get('/api/v1/core/special-projects' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },
  getWithId(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/special-projects/' + params.id + encodeURI(queryString)).then((response) => {
      return response.data.payload.specialProjects[0];
    });
  },
  getTasks(context, params) {
    return axios.get('/api/v1/core/special-projects/get-tasks/' + params.id).then((response) => {
      return response.data.payload;
    });
  },
  bulkUpdate(context, tasks) {
    return axios.post('/api/v1/core/special-project/bulkUpdate', { tasks }).then((response) => {
      return response.data;
    });
  },
  create(context, specialProject) {
    specialProject.projectType = specialProject.projectType.toLowerCase();
    return axios.put('/api/v1/core/special-projects', specialProject).then((response) => {
      return response.data.payload;
    });
  },
  update(context, project) {
    if (project.price) {
      project.price = parseFloat(project.price.toString().replace(/,/g, ''));
    }
    return axios.post(`/api/v1/core/special-project/${project.id}`, project).then((response) => {
      return response.data.payload;
    });
  },
  transition(context, params) {
    return axios
      .post(`/api/v1/core/special-project/${params.specialProjectId}/transition/${params.transition}`)
      .then((response) => {
        return response.data.payload;
      });
  },
  delete(context, specialProjectId) {
    return axios.delete(`/api/v1/core/special-project/delete/${specialProjectId}`).then((response) => {
      return response.data.payload;
    });
  },
  updateClient(context, params) {
    return axios
      .post(`/api/v1/special-project/${params.specialProjectId}/update-client`, params.request)
      .then((response) => {
        return response.data.payload;
      });
  },
  addScheduler(context, params) {
    return axios
      .put(`/api/v1/special-project/${params.specialProjectId}/scheduler/${params.userId}`)
      .then((response) => {
        return response.data.payload;
      });
  },
  deleteScheduler(context, params) {
    return axios
      .delete(`/api/v1/special-project/${params.specialProjectId}/scheduler/${params.userId}`)
      .then((response) => {
        return response.data.payload;
      });
  },
  addManager(context, params) {
    return axios.put(`/api/v1/special-project/${params.specialProjectId}/manager/${params.userId}`).then((response) => {
      return response.data.payload;
    });
  },
  deleteManager(context, params) {
    return axios
      .delete(`/api/v1/special-project/${params.specialProjectId}/manager/${params.userId}`)
      .then((response) => {
        return response.data.payload;
      });
  },
  updateServiceAddress(context, params) {
    console.log({ params });
    return axios
      .post(`/api/v1/special-project/${params.specialProjectId}/service-address`, params.address)
      .then((response) => {
        return response.data.payload;
      });
  },
  deleteServiceAddress(context, params) {
    return axios.delete(`/api/v1/special-project/${params.specialProjectId}/service-address`).then((response) => {
      return response.data.payload;
    });
  },
  updateBillingAddress(context, params) {
    return axios
      .post(`/api/v1/special-project/${params.specialProjectId}/billing-address`, params.address)
      .then((response) => {
        return response.data.payload;
      });
  },
  deleteBillingAddress(context, params) {
    return axios.delete(`/api/v1/special-project/${params.specialProjectId}/billing-address`).then((response) => {
      return response.data.payload;
    });
  },
  updateRecurringSchedule(context, params) {
    return axios
      .post(`/api/v1/special-project/${params.specialProjectId}/recurring-interval`, params.recurringSchedule)
      .then((response) => {
        return response.data.payload;
      });
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateSpecialProjects(state, specialProjects) {
    state.specialProjects = specialProjects;
  },

  updateFilters(state, filters) {
    state.filters = filters;
  },

  resetFilters(state) {
    // the headers gets updated dynamically based on status
    // Since the default headers is empty list, the headers needs unchanged.
    let headers = state.filters.headers;
    state.filters = defaultFilters;
    state.filters.headers = headers;
  },

  setCalendarView(state, booleanValue) {
    state.calendarView = booleanValue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
