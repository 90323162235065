import { utils } from '../../utils/utils';
import * as Sentry from '@sentry/vue';
import axios from '../../plugins/axios';
import { event } from '../../utils/event.js';

const defaultFilters = {
  id: null,
  region: 0,
  area: 0,
  building: 0,
  client: 0,
  dateFrom: null,
  dateTo: null,
  priority: null,
};

const state = () => ({
  fetching: false,
  tasks: [],
  filters: defaultFilters,
  sort: {
    by: 'tasks.created_at',
    direction: 'DESC',
  },
  statuses: {
    open: 'open',
    inProgress: 'in_progress',
    closed: 'closed',
    onHold: 'on_hold',
    cancelled: 'cancelled',
  },
  statusLabels: {
    open: 'open',
    in_progress: 'in progress',
    closed: 'closed',
    invoiced: 'invoiced',
    on_hold: 'on hold',
    cancelled: 'cancelled',
  },
  statusColor: {
    scheduled: '#5491F9',
    open: '#5491F9',
    in_progress: '#31CF97',
    completed: '#31CF97',
    closed: '#96999C',
    cancelled: '#96999C',
    on_hold: '#FF9001',
  },
});

const getters = {
  tasks: (state) => {
    return state.tasks;
  },
  statuses: (state) => {
    return state.statuses;
  },
  statusColor: (state) => {
    return state.statusColor;
  },
  filters: (state) => {
    return state.filters;
  },
  sort: (state) => {
    return state.sort;
  },
  getStatusColor: (state) => (code) => {
    return state.statusColor[code];
  },
};

const actions = {
  get(context) {
    // only query regions if we do not already have them.
    if (context.state.fetching !== true && context.state.tasks.length === 0) {
      context.commit('updateFetching', true);
      axios.get('/api/v1/core/tasks').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateTasks', response.data.payload.tasks);
      });
    }
  },
  getWithParams(context, params) {
    let queryString = '';

    if (params) {
      queryString = utils.formatQueryString(params);
    }

    return axios.get('/api/v1/core/tasks' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },
  getWithId(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/tasks/' + params.id + encodeURI(queryString)).then((response) => {
      return response.data.payload.tasks[0];
    });
  },
  getShareLink(context, taskId) {
    return axios.get('/api/v1/core/tasks/' + taskId + '/share-link').then((response) => {
      return response.data.payload.link;
    });
  },
  getNextOpenTask(context, taskId) {
    return axios.get('/api/v1/core/task/' + taskId + '/next').then((response) => {
      return response.data.payload;
    });
  },
  updateTransaction(context, transaction) {
    return axios.post('/api/v1/core/transaction/' + transaction.id, transaction).then((response) => {
      return response.data.payload;
    });
  },
  bulkUpdateInvoice(context, transactions) {
    return axios.post('/api/v1/core/transactions', { transactions }).then((response) => {
      return response.data;
    });
  },
  createTask(context, task) {
    let formData = new FormData();
    formData.append('building_id', task.building_id);
    formData.append('source', task.source);
    formData.append('type', task.type);
    formData.append('related_type_id', task.related_type_id || null);
    formData.append('area', task.area);
    formData.append('area_type', task.area_type || null);
    formData.append('area_type_id', task.area_type_id || null);
    formData.append('description', task.description || '');
    formData.append('priority_level', task.priority || null);
    formData.append('due_at', task.due_at || null);

    if (task.buildingCommonAreaId && task.buildingFloorId) {
      formData.append('building_common_area_id', task.buildingCommonAreaId);
      formData.append('building_floor_id', task.buildingFloorId);
    }

    task.files.forEach((file, index) => {
      formData.append('file' + index, file.data, file.data.name);
      formData.append('imageExifs[]', JSON.stringify(file.data.imageExif));
      formData.append('comments[]', file.data.comment || '');
    });

    return axios
      .post('/api/v1/core/tasks', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.status === 'error') {
          throw new Error(response.data.payload.messages);
        }

        return response.data.payload;
      })
      .catch((error) => {
        Sentry.addBreadcrumb({
          message: 'Add Task Error',
          data: {
            error,
            requestURL: '/api/v1/core/tasks',
            requestBody: formData,
          },
        });
        throw error;
      });
  },
  updateTaskStatus(ctx, { task, transition, serviceDate }) {
    return axios
      .post('/api/v1/core/tasks/update', {
        id: task.id,
        transition: transition,
        serviced_at: serviceDate,
      })
      .then((response) => {
        const updatedTask = { ...task };
        updatedTask.status = response.data.payload.status;
        updatedTask.completed_at = serviceDate;

        return updatedTask;
      });
  },
  delete(context, taskId) {
    return axios.delete('/api/v1/core/tasks/' + taskId);
  },
  async addAfterImages(context, { task, images }) {
    let formData = new FormData();
    formData.append('id', task.id);
    images.forEach((file, index) => {
      formData.append('file' + index, file.data, file.data.name);
      formData.append('imageExifs[]', JSON.stringify(file.data.imageExif));
    });

    event.emit('loading', 'uploading images...'.capitalize());
    return axios
      .post('/api/v1/core/tasks/resolutionImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        event.emit('alert', 'images uploaded successfully'.capitalize(), '', 'green');
        event.emit('loading', false);

        return response.data.payload;
      });
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateTasks(state, tasks) {
    state.tasks = tasks;
  },

  updateFilters(state, filters) {
    state.filters = filters;
  },

  updateSort(state, sort) {
    state.sort = sort;
  },

  resetFilters(state) {
    state.filters = defaultFilters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
